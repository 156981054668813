import React from "react"

import SEO from "../components/seo"
import Hero from "../components/Hero"
import Layout from "../components/layout"
import Staff from "../components/staff"
import P from "../components/p"

const Lesson = ({ pageContext: { lesson } }) => (
  <Layout>
    <Staff link={lesson.staff_url} />
    <Hero
      subject={lesson.subject_name.toLowerCase()}
      title={lesson.unit_title}
      subTitle={`Lesson ${lesson.num}`}
      crumbs={lesson.crumbs}
    />
    <div className={`container mx-auto py-8 lesson flex flex-col`}>
      <div className={`container mx-auto py-8 lesson flex flex-row`}>
        <div className="w-full md:w-1/5">Map</div>
        <div className="w-full md:w-4/5 flex flex-col">
          {/* Objective Start */}
          <div className="flex flex-col">
            <h2 className="uppercase">Objective</h2>
            <P content={lesson.objective} />
          </div>
          {/* Objective End */}
          {/* Materials Start */}
          {lesson.lesson_materials && lesson.lesson_materials.length > 0 && (
            <div className="flex flex-col">
              <h2 className="uppercase">
                {lesson.subject_name === "Math"
                  ? "MATERIALS AND RESOURCES"
                  : "READINGS AND MATERIALS"}
              </h2>
              <div>
                <ul>
                  {lesson.lesson_materials.map((obj, i) => {
                    return (
                      <li>
                        <P
                          content={`${obj.title_full} ${
                            obj.extras ? `${obj.extras}` : ""
                          }`}
                        />
                      </li>
                    )
                  })}
                </ul>
              </div>
            </div>
          )}

          {/* Materials End */}

          {/* Common Core Standards Start */}
          <div className="flex flex-col">
            <h2 className="uppercase">Common Core Standards</h2>
          </div>
          {/* Common Core Standards End */}
        </div>
      </div>
    </div>
  </Layout>
)

export default Lesson
